import cmEOjmS from "@/assets/images/manga/Attack on Titan 1.jpg";
import TbMxykQxqEQAU from "@/assets/images/manga/Demon Slayer 1.jpg";
import NzLONIKUL from "@/assets/images/manga/Deserter - Junji Ito Story Collection.jpg";
import mzqps from "@/assets/images/manga/Frankenstein - Junji Ito Story Collection.jpg";
import lbNeumEigtOeQkSXcmi from "@/assets/images/manga/Jujutsu Kaisen part 1 .jpg";
import fApLQAccxOVylCUnk from "@/assets/images/manga/Lovesickness - Junji Ito Story Collection.jpg";
import OxJDQcVzCmIQHl1 from "@/assets/images/manga/my dress up darling part 1.jpg";
import SwSxuviL from "@/assets/images/manga/My Hero Academia 1.jpg";
import oysSNyEPslxxir from "@/assets/images/manga/One Piece part 1 .jpg";
import qKlWGGyZVywRCXunLDB from "@/assets/images/manga/One Punch Man 1.jpg";
import kTrCXxLRn from "@/assets/images/manga/Shiver - Junji Ito Selected Stories.jpg";
import kkdYSVSBRSvSuahsOz from "@/assets/images/manga/Smashed - Junji Ito Story Collection.jpg";
import AUcjysq from "@/assets/images/manga/Soichi - Junji Ito Story Collection.jpg";
import BYrLHFF from "@/assets/images/manga/SPY×FAMILY part 1 .jpg";
import AEEdiEomV from "@/assets/images/manga/The Eminence in Shadow 1.jpg";
import HotuP from "@/assets/images/manga/Tombs - Junji Ito Story Collection.jpg";
import Berserk_1 from "@/assets/images/manga/Berserk 1.jpg";
import Chained_Soldier_1 from "@/assets/images/manga/Chained Soldier 1.jpg";
import The_Dangers_in_My_Heart_1 from "@/assets/images/manga/The Dangers in My Heart 1.jpg";
import Dororo_1 from "@/assets/images/manga/Dororo 1.jpg";
import Tokyo_Revengers_1 from "@/assets/images/manga/Tokyo_Revengers_1.jpg";
import That_Time_I_Got_Reincarnated_as_a_Slime_1 from "@/assets/images/manga/That_Time_I_Got_Reincarnated_as_a_Slime_1.jpg";
import Mashle_Magic_and_Muscles_1 from "@/assets/images/manga/Mashle_Magic_and_Muscles_1.jpg";
import Crayon_Shin_chan from "@/assets/images/manga/Crayon_Shin_chan.jpg";
import Doraemon from "@/assets/images/manga/Doraemon.jpeg";
import Kaiju_No_8 from "@/assets/images/manga/Kaiju_No_8.jpeg";
import The_Apothecary_Diaries from "@/assets/images/manga/The_Apothecary_Diaries.jpeg";
import Nagatoro from "@/assets/images/manga/Nagatoro.jpeg";
import Mushoku from "@/assets/images/manga/Mushoku.jpeg";

export const Mangas = [
  {
    image: Mushoku,
    title: "Mushoku Tensei - Jobless Reincarnation",
    price: 6,
  },
  {
    image: Nagatoro,
    title: "Don't Toy With Me, Miss Nagatoro",
    price: 6,
  },
  {
    image: Kaiju_No_8,
    title: "Kaiju No. 8",
    price: 6,
  },
  {
    image: The_Apothecary_Diaries,
    title: "The Apothecary Diaries",
    price: 6,
  },
  {
    image: Doraemon,
    title: "Doraemon",
    price: 8,
  },
  {
    image: Crayon_Shin_chan,
    title: "Crayon Shin chan",
    price: 6,
  },
  {
    image: cmEOjmS,
    title: "Attack on Titan",
    price: 6,
  },

  {
    image: TbMxykQxqEQAU,
    title: "Demon Slayer",
    price: 6,
  },

  {
    image: lbNeumEigtOeQkSXcmi,
    title: "Jujutsu Kaisen",
    price: 6,
  },

  {
    image: OxJDQcVzCmIQHl1,
    title: "My dress up darling",
    price: 6,
  },

  {
    image: SwSxuviL,
    title: "My Hero Academia",
    price: 6,
  },

  {
    image: oysSNyEPslxxir,
    title: "One Piece ",
    price: 6,
  },

  {
    image: qKlWGGyZVywRCXunLDB,
    title: "One Punch Man",
    price: 6,
  },

  {
    image: BYrLHFF,
    title: "SPYxFAMILY",
    price: 6,
  },

  {
    image: AEEdiEomV,
    title: "The Eminence in Shadow",
    price: 6,
  },
  {
    image: Berserk_1,
    title: "Berserk",
    price: 6,
  },

  {
    image: Dororo_1,
    title: "Dororo",
    price: 6,
  },
  {
    image: Chained_Soldier_1,
    title: "Chained Soldier",
    price: 6,
  },
  {
    image: The_Dangers_in_My_Heart_1,
    title: "The Dangers in My Heart",
    price: 6,
  },

  {
    image: kTrCXxLRn,
    title: "Shiver - Junji Ito Selected Stories",
    price: 8,
  },

  {
    image: kkdYSVSBRSvSuahsOz,
    title: "Smashed - Junji Ito Story Collection",
    price: 8,
  },

  {
    image: AUcjysq,
    title: "Soichi - Junji Ito Story Collection",
    price: 8,
  },
  {
    image: NzLONIKUL,
    title: "Deserter - Junji Ito Story Collection",
    price: 8,
  },

  {
    image: mzqps,
    title: "Frankenstein - Junji Ito Story Collection",
    price: 8,
  },
  {
    image: fApLQAccxOVylCUnk,
    title: "Lovesickness - Junji Ito Story Collection",
    price: 8,
  },

  {
    image: HotuP,
    title: "Tombs - Junji Ito Story Collection",
    price: 8,
  },

  {
    image: Tokyo_Revengers_1,
    title: "Tokyo Revengers",
    price: 6,
  },

  {
    image: That_Time_I_Got_Reincarnated_as_a_Slime_1,
    title: "That Time I Got Reincarnated as a Slime",
    price: 6,
  },
  {
    image: Mashle_Magic_and_Muscles_1,
    title: "Mashle Magic and Muscles ",
    price: 6,
  },
];
